.post-editor {
    width: 100%;
    height: 100%;
}

.left-side, .right-side {
    display: inline-block;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
}

.left-side {
    float: left;
}

.area-button-post {
    position: fixed;
    right: 5rem;
    bottom: 5rem;
    display: flex;
    gap: 1.6rem;
}