#post-state-select-toggle {
    margin: 1.6rem 0;
}

.page-ui-editor {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 3.2rem;
    box-sizing: border-box;
    overflow: auto;
}

.list-edit-post, .list-edit-post li{
    padding: 0;
    margin: 1.6rem 0 0;
    list-style: none;
}

#post-basedlang-select-toggle {
    margin-left: 1.6rem;
}